var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card_", staticStyle: { position: "relative" } },
    [
      _c(
        "h1",
        { staticClass: "font13pt titleColor text-center pt-3" },
        [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
        ],
        1
      ),
      _c("div", { staticClass: "score d-flex flex-column align-center pt-8" }, [
        _vm._m(0),
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "value_score" }, [
            _c(
              "span",
              {
                staticClass:
                  "font37pt font-weight-thin titleColor text-uppercase",
              },
              [_vm._v(" " + _vm._s(_vm.summatory) + " ")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "info_score d-flex align-stretch align-content-space-between flex-column",
            },
            [
              _c(
                "span",
                { staticClass: "sentiment_score" },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color:
                          _vm.socialNetworks.totalHistogramsSamples >
                          _vm.newspapers.totalHistogramsSamples
                            ? _vm.socialNetworks.color
                            : _vm.newspapers.color,
                      },
                    },
                    [_vm._v(" fas fa-caret-down ")]
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "perc_esocre leyendColor font10pt" }, [
                _vm._v(" " + _vm._s(_vm.score.perc) + "% "),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            bottom: "0",
            padding: "0 5% 0 5% !important",
          },
        },
        [
          _c("apexchart", {
            attrs: {
              type: "area",
              height: "125",
              options: _vm.chartOptionsSamples,
              series: [
                {
                  name: "Social Networks",
                  data: this.socialNetworks.histogramsList,
                  color: "#ff9a9a",
                },
                {
                  name: "Newspapers",
                  data: this.newspapers.histogramsList,
                  color: "#00b1f2",
                },
              ],
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-row mb-5" }, [
      _c("div", { staticClass: "legend legend-basic" }, [
        _c("div", {
          staticClass: "legend-basic__icon",
          staticStyle: { background: "#ff9a9a" },
        }),
        _c("div", { staticClass: "legend-basic__text" }, [
          _vm._v("Social Networks"),
        ]),
      ]),
      _c("div", { staticClass: "legend legend-basic" }, [
        _c("div", {
          staticClass: "legend-basic__icon",
          staticStyle: { background: "#00b1f2" },
        }),
        _c("div", { staticClass: "legend-basic__text" }, [
          _vm._v("Newspapers"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }