var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: {
        bottom: "",
        "max-width": "300",
        "content-class": "font12pt bgColorPrincipal text-center pt-4 pb-4",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        size: _vm.tooltipSize ? _vm.tooltipSize : "13",
                        color: "#a2acc4",
                      },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" fas fa-info-circle ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.tooltipText)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }