var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card_ d-flex justify-space-between flex-column" },
    [
      _c("div", [
        _c(
          "h1",
          { staticClass: "font13pt titleColor text-center pt-3" },
          [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
          ],
          1
        ),
        _c(
          "span",
          { staticClass: "d-block text-center font10pt leyendColor" },
          [_vm._v(_vm._s(_vm.subtitle))]
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "genderBox pl-4 pr-4 d-flex justify-space-between align-center",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-center aling-center" },
            [
              _c(
                "span",
                { staticClass: "font37pt font-weight-thin ColorMale" },
                [
                  _vm._v(" " + _vm._s(_vm.currentGender.male.perc) + " "),
                  _c("span", { staticClass: "dgraph_card_percentage" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-space-around align-center" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dgraph_card_percentage_xs",
                      staticStyle: { color: "#00b1f2" },
                    },
                    [_vm._v("%")]
                  ),
                  _c("v-icon", { attrs: { size: "12", color: "#00b1f2" } }, [
                    _vm._v("fas fa-mars"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-center aling-center" },
            [
              _c(
                "span",
                { staticClass: "font37pt font-weight-thin ColorFemale" },
                [
                  _vm._v(" " + _vm._s(_vm.currentGender.female.perc) + " "),
                  _c("span", { staticClass: "dgraph_card_percentage" }, [
                    _vm._v("%"),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-space-around align-center" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dgraph_card_percentage_xs",
                      staticStyle: { color: "#ff9a9a" },
                    },
                    [_vm._v("%")]
                  ),
                  _c("v-icon", { attrs: { size: "12", color: "#ff9a9a" } }, [
                    _vm._v("fas fa-venus"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "infoGender text-center" }, [
        _c("span", { staticClass: "font12pt leyendColor" }, [
          _vm._v(
            " " +
              _vm._s(_vm.currentGender.other.perc) +
              " % " +
              _vm._s(_vm.currentGender.other.name) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "genderInfo d-flex justify-space-between" }, [
        _c(
          "div",
          {
            staticClass:
              "infoGenderBox bgMale d-flex justify-center align-content-center",
          },
          [
            _c(
              "span",
              { staticClass: "font15pt font-weight-bold text-uppercase" },
              [_vm._v(_vm._s(_vm.currentGender.male.value))]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "infoGenderBox bgFemale d-flex justify-center aling-center",
          },
          [
            _c(
              "span",
              { staticClass: "font15pt font-weight-bold text-uppercase" },
              [_vm._v(_vm._s(_vm.currentGender.female.value))]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }