var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {},
    [
      _c(
        "v-row",
        [
          _c("div", { staticClass: "col mb-5 d-flex align-center" }, [
            _c("div", { staticClass: "mr-auto" }, [
              _c(
                "h1",
                {
                  staticClass: "font18pt titleColor font-weight-bold",
                  staticStyle: { "max-width": "120px" },
                },
                [_vm._v(" " + _vm._s(_vm.contextName) + " ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-center multi-select justify-end" },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c("span", { staticClass: "font12pt parColor" }, [
                    _vm._v(" Filter by: "),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "margin-select mx-3" },
                    [
                      _c("vue-multi-select", {
                        attrs: {
                          "select-options": _vm.socialNetworkFilter.data,
                          options: _vm.socialNetworkFilter.options,
                          btnLabel: _vm.socialNetworkFilter.btnLabel,
                          position: "bottom-right",
                          disabled: _vm.socialNetworkFilter.disabled,
                        },
                        on: {
                          close: function ($event) {
                            return _vm.updateActiveGroup()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function ({ option }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex align-center pl-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSocialNetworkFilterOption(
                                          option
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "mr-2",
                                      attrs: { type: "checkbox" },
                                      domProps: { checked: option.selected },
                                    }),
                                    _c("span", [_vm._v(_vm._s(option.name))]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.socialNetworkFilter.values,
                          callback: function ($$v) {
                            _vm.$set(_vm.socialNetworkFilter, "values", $$v)
                          },
                          expression: "socialNetworkFilter.values",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.driverFilter.data.length
                    ? _c(
                        "div",
                        { staticClass: "multiselectContainer" },
                        [
                          _c("vue-multi-select", {
                            attrs: {
                              "select-options": _vm.driverFilter.data,
                              options: _vm.driverFilter.options,
                              btnLabel: _vm.driverFilter.btnLabel,
                              filters: _vm.driverFilter.filters,
                              position: "bottom-right",
                              emptyTabText: "No drivers",
                            },
                            on: {
                              close: function ($event) {
                                return _vm.closeDriverBoardsFilter()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "option",
                                  fn: function ({ option }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            font: "1em 'Oxygen' !important",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: option.selected,
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                option.mask ||
                                                  option.term ||
                                                  `${
                                                    option.newspaper_mask
                                                      ? option.page_url
                                                      : ""
                                                  }`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              295099276
                            ),
                            model: {
                              value: _vm.driverFilter.values,
                              callback: function ($$v) {
                                _vm.$set(_vm.driverFilter, "values", $$v)
                              },
                              expression: "driverFilter.values",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _vm.modalOpen
            ? _c("ContextModal", { on: { close: _vm.handleCreateModalClose } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }