var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card_" }, [
    _c("div", [
      _c(
        "h1",
        { staticClass: "font13pt titleColor text-center pt-3" },
        [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
        ],
        1
      ),
      _c("span", { staticClass: "d-block text-center font10pt leyendColor" }, [
        _vm._v(_vm._s(_vm.subtitle)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "boxInfluencer pl-4 pr-4 mt-3" },
      _vm._l(_vm.topData, function (item) {
        return _c(
          "div",
          { key: item.name, staticClass: "fileInfluencer d-flex mb-3" },
          [
            _c("div", { staticClass: "name d-flex mr-auto" }, [
              _c("div", { staticClass: "d-flex flex-column ml-2" }, [
                _c(
                  "span",
                  { staticClass: "spanName d-block font12pt parColor" },
                  [_vm._v(_vm._s(item.name))]
                ),
                _c(
                  "span",
                  { staticClass: "spanProfile d-block font10pt leyendColor" },
                  [_vm._v(_vm._s(item.profile))]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "value d-flex" },
              [
                item.tw
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "13", color: "#1DA1F2" },
                      },
                      [_vm._v(" fab fa-twitter ")]
                    )
                  : _vm._e(),
                item.fb
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "13", color: "#1877f2" },
                      },
                      [_vm._v(" fab fa-facebook ")]
                    )
                  : _vm._e(),
                item.ig
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "13", color: "#ff69a1" },
                      },
                      [_vm._v(" fab fa-instagram ")]
                    )
                  : _vm._e(),
                item.lk
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "13", color: "#0a66c2" },
                      },
                      [_vm._v(" fab fa-linkedin ")]
                    )
                  : _vm._e(),
                item.nws
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { size: "13", color: "#444444" },
                      },
                      [_vm._v(" fa-solid fa-newspaper ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }