var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mb-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Sentiment share"),
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  attrs: { right: "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Social Networks")]),
                  _c("v-tab", [_vm._v("Newspapers")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(
        [_vm.socialNetworks.data, _vm.newspapers.data],
        function (network, indexNetwork) {
          return _vm.tab === indexNetwork
            ? _c(
                "v-row",
                {
                  key: indexNetwork,
                  staticClass: "sentimentshared__content pl-4 pr-4",
                },
                _vm._l(network, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "sentimentshared__content__item",
                      attrs: { md: "4" },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4", attrs: { flat: "" } },
                        [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "mb-4 font-weight-light sentimentshared__content__item__title titleColor font15pt text-center text-capitalize",
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "sentimentshared__content__item__value d-flex align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col pa-0 sentiment__box__item__value__perc bgColorBase flex-grow-1 flex-shrink-0",
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "sentiment__box__item__value__perc--color position-absolute",
                                    class: `bgColor${item.title}`,
                                    style: { width: item.value + "%" },
                                  }),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-3 parColor font-weight-light flex-grow-0 flex-shrink-0",
                                },
                                [_vm._v(_vm._s(item.value) + "%")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }