var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "overview" } },
    [
      _c(
        "v-app",
        [
          _c("v-main", { staticClass: "bgColorBase" }, [
            _c(
              "div",
              { staticClass: "main-container" },
              [
                _vm.loading ? _c("Loader") : _vm._e(),
                _c("router-view", { on: { setLoader: _vm.setLoader } }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }