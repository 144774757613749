var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "pt-5 mt-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mb-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Words Sentiment"),
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [
        _c(
          "div",
          {
            staticClass: "sentimentword col pa-0 d-flex justify-space-between",
          },
          [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "sentimentword__box-gradient" }),
              _c(
                "div",
                {
                  staticClass:
                    "sentimentword__box-gradient_names d-flex flex-column justify-space-between pl-2",
                },
                [
                  _c("span", { staticClass: "titleColor" }, [
                    _vm._v("Positive"),
                  ]),
                  _c("span", { staticClass: "titleColor" }, [
                    _vm._v("Neutral"),
                  ]),
                  _c("span", { staticClass: "titleColor" }, [
                    _vm._v("Negative"),
                  ]),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "sentimentword__cloud position-relative" },
              [
                _c(
                  "div",
                  {
                    staticClass: "sentimentword__cloud__box position-absolute",
                  },
                  [
                    _vm.cloudWords
                      ? _c("vue-word-cloud", {
                          staticClass: "font-base",
                          staticStyle: {
                            height: "250px",
                            width: "100%",
                            "z-index": "1",
                          },
                          attrs: {
                            words: _vm.cloudWords[0],
                            "font-family": "Oxygen",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ text, weight, word }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        ref: word[3],
                                        staticClass:
                                          "font-base position-relative",
                                        class: {
                                          positive: word[2] === "pos",
                                          negative: word[2] === "neg",
                                          neutral: word[2] === "neu",
                                        },
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { title: weight },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onWordClick(word[3])
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(text) + " ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4074162345
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.modalOpen
                  ? _c("WordModal", {
                      staticClass: "modal_component position-absolute white",
                      attrs: { contentModal: _vm.modalData },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "sentimentword__table table--general" },
              [
                _c("v-data-table", {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.itemsTable,
                    "hide-default-header": false,
                    "hide-default-footer": true,
                    "items-per-page": -1,
                    light: true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `item.type`,
                        fn: function ({ item }) {
                          return [
                            item.type.includes("tw")
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#1da1f2" } },
                                  [_vm._v(" fab fa-twitter ")]
                                )
                              : _vm._e(),
                            item.type.includes("fb")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#1877f2" },
                                  },
                                  [_vm._v(" fab fa-facebook ")]
                                )
                              : _vm._e(),
                            item.type.includes("ig")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#ff69a1" },
                                  },
                                  [_vm._v(" fab fa-instagram ")]
                                )
                              : _vm._e(),
                            item.type.includes("lk")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#0a66c2" },
                                  },
                                  [_vm._v(" fab fa-linkedin ")]
                                )
                              : _vm._e(),
                            item.type.includes("nws")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#444444" },
                                  },
                                  [_vm._v(" fa-solid fa-newspaper ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.sentiment`,
                        fn: function ({ item }) {
                          return [
                            item.sentiment == "neu"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#828a96" } },
                                  [_vm._v(" far fa-hand-point-right ")]
                                )
                              : _vm._e(),
                            item.sentiment == "pos"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#9fd895" } },
                                  [_vm._v(" far fa-thumbs-up ")]
                                )
                              : _vm._e(),
                            item.sentiment == "neg"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#ff7e7e" } },
                                  [_vm._v(" far fa-thumbs-down ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.word`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "text-truncate",
                                staticStyle: { "max-width": "120px" },
                              },
                              [_vm._v(" " + _vm._s(item.word) + " ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }