var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mb-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v(" Influencer & Accounts Ranking "),
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "table--general" },
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.itemsTable,
                    "hide-default-header": false,
                    "hide-default-footer": true,
                    "items-per-page": 15,
                    light: true,
                    "calculate-widths": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `item.accounts`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                staticStyle: {
                                  "line-height": "1em",
                                  "max-width": "250px",
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "white-space": "nowrap",
                                },
                              },
                              [
                                _c("v-avatar", { attrs: { size: "25" } }, [
                                  _c("img", {
                                    attrs: { alt: "user", src: item.avatar },
                                    on: {
                                      error: function ($event) {
                                        $event.target.src =
                                          "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                      },
                                    },
                                  }),
                                ]),
                                item.network == "tw"
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "padding-left": "10px",
                                          margin: "0",
                                        },
                                      },
                                      [_vm._v("@" + _vm._s(item.accounts))]
                                    )
                                  : _vm._e(),
                                item.network != "tw"
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "padding-left": "10px",
                                          margin: "0",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.accounts))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.network`,
                        fn: function ({ item }) {
                          return [
                            item.network.includes("fb")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#598ec2" },
                                  },
                                  [_vm._v(" fab fa-facebook ")]
                                )
                              : _vm._e(),
                            item.network.includes("tw")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#1da1f2" },
                                  },
                                  [_vm._v(" fab fa-twitter ")]
                                )
                              : _vm._e(),
                            item.network.includes("ig")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#ff69a1" },
                                  },
                                  [_vm._v(" fab fa-instagram ")]
                                )
                              : _vm._e(),
                            item.network.includes("lk")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#0a66c2" },
                                  },
                                  [_vm._v(" fab fa-linkedin ")]
                                )
                              : _vm._e(),
                            item.network.includes("nws")
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { small: "", color: "#444444" },
                                  },
                                  [_vm._v(" fa-solid fa-newspaper ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.sentiment`,
                        fn: function ({ item }) {
                          return [
                            item.sentiment == "neu"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#828a96" } },
                                  [_vm._v(" far fa-hand-point-right ")]
                                )
                              : _vm._e(),
                            item.sentiment == "pos"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#9fd895" } },
                                  [_vm._v(" far fa-thumbs-up ")]
                                )
                              : _vm._e(),
                            item.sentiment == "neg"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#ff7e7e" } },
                                  [_vm._v(" far fa-thumbs-down ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("div", { staticClass: "d-flex bgColorBase" }, [
                  _c("div", { staticClass: "col pa-1 d-flex" }, [
                    _c("div", { staticClass: "col-2" }),
                    _c(
                      "div",
                      { staticClass: "col d-flex justify-start align-center" },
                      [
                        _c(
                          "span",
                          { staticClass: "parColor font12pt font-weight-bold" },
                          [_vm._v("Total")]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col d-flex justify-end align-center" },
                    [
                      _c("div", { staticClass: "tcom text-center" }, [
                        _c(
                          "span",
                          { staticClass: "parColor font12pt font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("withComma")(_vm.totalCommunity)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "text-center tint" }, [
                        _c(
                          "span",
                          { staticClass: "parColor font12pt font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("withComma")(_vm.totalInteractions)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }