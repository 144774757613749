var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticStyle: { "max-height": "70px" }, attrs: { md: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center mt-5" },
                [
                  _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                    _vm._v(" Gender Sentiment "),
                  ]),
                  _c("tooltipComponent", {
                    attrs: { tooltipText: _vm.tooltip },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "mb-5" }, [
                _c("span", { staticClass: "d-block font10pt leyendColor" }, [
                  _vm._v("At all Social Networks"),
                ]),
              ]),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", staticStyle: { "margin-bottom": "15px" } },
            [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "box-gender bgMale d-flex aling-center justify-center",
                  },
                  [
                    _c("v-icon", { attrs: { size: "40", color: "#fff" } }, [
                      _vm._v("fas fa-mars"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold mb-1" },
                    [_vm._v("Found")]
                  ),
                  _c("span", { staticClass: "font12pt parColor" }, [
                    _vm._v(_vm._s(_vm.male.found)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold" },
                    [_vm._v("Sentiment")]
                  ),
                  _vm.male.sentiment == "pos"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#9fd895", size: "18" } },
                        [_vm._v("far fa-thumbs-up")]
                      )
                    : _vm._e(),
                  _vm.male.sentiment == "neg"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#ff7e7e", size: "18" } },
                        [_vm._v("far fa-thumbs-down")]
                      )
                    : _vm._e(),
                  _vm.male.sentiment == "neu"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#828a96", size: "22" } },
                        [_vm._v("fas fa-ellipsis-h")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold mb-1" },
                    [_vm._v("Emotions")]
                  ),
                  _c(
                    "span",
                    { staticClass: "font12pt parColor text-capitalize" },
                    [_vm._v(_vm._s(_vm.male.emotions))]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", staticStyle: { "margin-bottom": "15px" } },
            [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "box-gender bgFemale d-flex aling-center justify-center",
                  },
                  [
                    _c("v-icon", { attrs: { size: "40", color: "#fff" } }, [
                      _vm._v("fas fa-venus"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold mb-1" },
                    [_vm._v("Found")]
                  ),
                  _c("span", { staticClass: "font12pt parColor" }, [
                    _vm._v(_vm._s(_vm.female.found)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold" },
                    [_vm._v("Sentiment")]
                  ),
                  _vm.female.sentiment == "pos"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#9fd895", size: "18" } },
                        [_vm._v("far fa-thumbs-up")]
                      )
                    : _vm._e(),
                  _vm.female.sentiment == "neg"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#ff7e7e", size: "18" } },
                        [_vm._v("far fa-thumbs-down")]
                      )
                    : _vm._e(),
                  _vm.female.sentiment == "neu"
                    ? _c(
                        "v-icon",
                        { attrs: { color: "#828a96", size: "22" } },
                        [_vm._v("fas fa-ellipsis-h")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex flex-column text-center justify-center",
                },
                [
                  _c(
                    "span",
                    { staticClass: "font12pt parColor font-weight-bold mb-1" },
                    [_vm._v("Emotions")]
                  ),
                  _c(
                    "span",
                    { staticClass: "font12pt parColor text-capitalize" },
                    [_vm._v(_vm._s(_vm.female.emotions))]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }