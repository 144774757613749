var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-row",
                { attrs: { "align-items": "center" } },
                [
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mt-5" },
                      [
                        _c(
                          "h1",
                          { staticClass: "font15pt font-weight-bold mr-2" },
                          [_vm._v("Male Sentiment")]
                        ),
                        _c("tooltipComponent", {
                          attrs: { tooltipText: _vm.tooltip },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mb-5" }, [
                      _c(
                        "span",
                        { staticClass: "d-block font10pt leyendColor" },
                        [_vm._v("At all Social Networks")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("apexchart", {
                        attrs: {
                          height: "350",
                          options: _vm.chartOptions,
                          series: _vm.male.series,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-row",
                { attrs: { "align-items": "center" } },
                [
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mt-5" },
                      [
                        _c(
                          "h1",
                          { staticClass: "font15pt font-weight-bold mr-2" },
                          [_vm._v("Female Sentiment")]
                        ),
                        _c("tooltipComponent", {
                          attrs: { tooltipText: _vm.tooltip },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mb-5" }, [
                      _c(
                        "span",
                        { staticClass: "d-block font10pt leyendColor" },
                        [_vm._v("At all Social Networks")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("apexchart", {
                        attrs: {
                          height: "350",
                          options: _vm.chartOptions,
                          series: _vm.female.series,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }