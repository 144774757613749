var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "v-main__influencers" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mb-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v(" Top 15 Influencers & Accounts "),
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass:
                "influencers-account-container pa-5 pt-0 pb-0 d-flex justify-space-between flex-wrap",
            },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "influencers-account-container__item position-relative mb-4",
                },
                [
                  _c("div", {
                    staticClass: "content--ribbon position-absolute",
                    class: {
                      ribbonpositive: item.values.sentiment == "pos",
                      ribbonnegative: item.values.sentiment == "neg",
                      ribbonneutral: item.values.sentiment == "neu",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "influencers-account-container__item--score pa-3 pb-0",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "font11pt font-weight-light parColor" },
                        [
                          _vm._v("Influencer Score: "),
                          _c("span", [_vm._v(_vm._s(item.values.score))]),
                          _vm._v("%"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "influencers-account-container__item--userinfo d-flex align-center pa-3",
                    },
                    [
                      _c("v-avatar", { attrs: { size: "50" } }, [
                        _c("img", {
                          attrs: { alt: "user", src: item.userAvatar },
                          on: {
                            error: function ($event) {
                              $event.target.src =
                                "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "d-flex flex-column pl-2" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-bold titleInfluencerColor font18pt text-truncate",
                            staticStyle: { "max-width": "110px" },
                          },
                          [
                            item.socialNetwork == "tw"
                              ? _c("span", [_vm._v("@")])
                              : _vm._e(),
                            _vm._v(_vm._s(item.userName) + " "),
                          ]
                        ),
                        _c(
                          "span",
                          { staticClass: "ColorMale d-flex font10pt" },
                          [
                            item.socialNetwork == "fb"
                              ? _c(
                                  "v-icon",
                                  { attrs: { size: "10", color: "#1877f2" } },
                                  [_vm._v(" fab fa-facebook ")]
                                )
                              : _vm._e(),
                            item.socialNetwork == "tw"
                              ? _c(
                                  "v-icon",
                                  { attrs: { size: "10", color: "#00b1f2" } },
                                  [_vm._v(" fab fa-twitter ")]
                                )
                              : _vm._e(),
                            item.socialNetwork == "ig"
                              ? _c(
                                  "v-icon",
                                  { attrs: { size: "10", color: "#ff69a1" } },
                                  [_vm._v(" fab fa-instagram ")]
                                )
                              : _vm._e(),
                            item.socialNetwork == "lk"
                              ? _c(
                                  "v-icon",
                                  { attrs: { size: "10", color: "#0a66c2" } },
                                  [_vm._v(" fab fa-linkedin ")]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "ml-1" }, [
                              item.socialNetwork == "tw"
                                ? _c("span", [_vm._v("@")])
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "cursorPoint",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendUrl(item, _vm.data)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.userName))]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "influencers-account-container__item--values__box pa-3 pt-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "influencers-account-container__item--values__box-item d-flex mt-1",
                        },
                        [
                          _c("div", { staticClass: "col pa-0" }, [
                            _c("span", { staticClass: "parColor font12pt" }, [
                              _vm._v("Interactions:"),
                            ]),
                          ]),
                          _c("div", { staticClass: "col pa-0" }, [
                            _c("span", { staticClass: "parColor font12pt" }, [
                              _vm._v(_vm._s(item.values.interactions)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "influencers-account-container__item--values__box-item d-flex mt-1",
                        },
                        [
                          _c("div", { staticClass: "col pa-0" }, [
                            _c("span", { staticClass: "parColor font12pt" }, [
                              _vm._v("Sentiment:"),
                            ]),
                          ]),
                          _c("div", { staticClass: "col pa-0" }, [
                            item.values.sentiment == "pos"
                              ? _c(
                                  "span",
                                  { staticClass: "font12pt positive" },
                                  [_vm._v("Positive")]
                                )
                              : _vm._e(),
                            item.values.sentiment == "neg"
                              ? _c(
                                  "span",
                                  { staticClass: "font12pt negative" },
                                  [_vm._v("Negative")]
                                )
                              : _vm._e(),
                            item.values.sentiment == "neutral" ||
                            item.values.sentiment == "neu"
                              ? _c(
                                  "span",
                                  { staticClass: "neutral font12pt" },
                                  [_vm._v("Neutral")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "400" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "button",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "influencers-account-container__item--button mt-2 col pt-3 pb-3 bgColorBase parColor",
                                          },
                                          "button",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" View Post ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.open,
                            callback: function ($$v) {
                              _vm.$set(item, "open", $$v)
                            },
                            expression: "item.open",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "modalcontent elevation-2 pa-3 white",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__title pb-4 position-relative",
                                },
                                [
                                  item.socialNetwork == "fb" ||
                                  item.socialNetwork == "ig" ||
                                  item.socialNetwork == "lk"
                                    ? _c(
                                        "span",
                                        { staticClass: "leyendColor font10pt" },
                                        [_vm._v("Top 3 post by interactions")]
                                      )
                                    : _vm._e(),
                                  item.socialNetwork == "tw"
                                    ? _c(
                                        "span",
                                        { staticClass: "leyendColor font10pt" },
                                        [_vm._v("Top 3 tweets by interactions")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "close-modal-btn position-absolute",
                                      on: {
                                        click: function ($event) {
                                          item.open = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#37afef" } },
                                        [_vm._v(" fas fa-times-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "modalcontent__content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "modalcontent__content__item",
                                      class: {
                                        heightAuto:
                                          item.modalcontent.length <= 1,
                                      },
                                    },
                                    [
                                      item.modalcontent.length === 0
                                        ? _c(
                                            "div",
                                            { staticClass: "pa-4 text-center" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass: "text--disabled",
                                                },
                                                [_vm._v("NO DATA")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.modalcontent
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              item.modalcontent,
                                              function (data, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "mb-5 pb-3 border_bt",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modalcontent__content__user d-flex",
                                                      },
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            attrs: {
                                                              size: "40",
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                alt: "user",
                                                                src: data.userAvatar,
                                                              },
                                                              on: {
                                                                error:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.target.src =
                                                                      "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modalcontent__content__userinfo d-flex flex-column justify-end pl-2",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font12pt font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data.userName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ColorMale font10pt",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " @" +
                                                                      _vm._s(
                                                                        data.userName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "leyendColor font10pt",
                                                              },
                                                              [
                                                                _c("span", {}, [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data.postDate
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _vm._v(" - "),
                                                                _c("span", {}, [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data.postTime
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modalcontent__content__comments mt-2",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modalcontent__content__comments__text pt-2",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "parColor font12pt",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.postcontent
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        data.postImage
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "modalcontent__content__comments__image mt-5",
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    src: data.postImage,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modalcontent__content__comments__reactions d-flex mt-5 mb-2",
                                                          },
                                                          [
                                                            data.likeCount &&
                                                            item.socialNetwork ==
                                                              "fb"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "modalcontent__content__comments__reactions__item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size: "10",
                                                                          color:
                                                                            "#a2acc4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " fas fa-thumbs-up "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "leyendColor font10pt pl-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            data.likeCount
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            data.sharedCount
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "modalcontent__content__comments__reactions__item pl-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size: "10",
                                                                          color:
                                                                            "#a2acc4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " fas fa-share "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "leyendColor font10pt pl-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            data.sharedCount
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            data.comentsCount
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "modalcontent__content__comments__reactions__item pl-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size: "10",
                                                                          color:
                                                                            "#a2acc4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " fas fa-comments "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "leyendColor font10pt pl-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            data.comentsCount
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            data.retweetCount
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "modalcontent__content__comments__reactions__item pl-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size: "10",
                                                                          color:
                                                                            "#a2acc4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " fas fa-retweet "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "leyendColor font10pt pl-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            data.retweetCount
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            data.likeCount &&
                                                            item.socialNetwork ==
                                                              "tw"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "modalcontent__content__comments__reactions__item pl-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size: "10",
                                                                          color:
                                                                            "#a2acc4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " fas fa-heart "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "leyendColor font10pt pl-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            data.likeCount
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }