import { render, staticRenderFns } from "./topPublicationsRelatedTrendingTopic.vue?vue&type=template&id=6267b214&"
import script from "./topPublicationsRelatedTrendingTopic.vue?vue&type=script&lang=js&"
export * from "./topPublicationsRelatedTrendingTopic.vue?vue&type=script&lang=js&"
import style0 from "./topPublicationsRelatedTrendingTopic.vue?vue&type=style&index=0&id=6267b214&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/isc-listen/listen/listen-microfront-v3/isc-overview/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6267b214')) {
      api.createRecord('6267b214', component.options)
    } else {
      api.reload('6267b214', component.options)
    }
    module.hot.accept("./topPublicationsRelatedTrendingTopic.vue?vue&type=template&id=6267b214&", function () {
      api.rerender('6267b214', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/overview/trendtopic/topPublicationsRelatedTrendingTopic.vue"
export default component.exports