var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "context-box", staticStyle: { width: "90%" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mb-3", attrs: { sm: 12, md: 12, lg: 5 } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "font12pt leyendColor" }, [
                      _vm._v("What:"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-1 pt-1", attrs: { cols: "12" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newDriverName,
                            expression: "newDriverName",
                          },
                        ],
                        staticClass:
                          "input pl-3 font12pt titleColor font-weight-bold w-100",
                        attrs: {
                          maxlength: "40",
                          type: "text",
                          placeholder:
                            "Term, mention or Hashtag (One at a time)...",
                        },
                        domProps: { value: _vm.newDriverName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.newDriverName = $event.target.value
                          },
                        },
                      }),
                      _vm.warningText != ""
                        ? _c("div", { staticClass: "pt-1 textError ml-0" }, [
                            _c("span", [_vm._v(_vm._s(_vm.warningText))]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mb-3", attrs: { sm: 6, md: 6, lg: 3 } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "font12pt leyendColor" }, [
                      _vm._v("Country:"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "pr-1 pl-1 pt-1", attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass:
                          "selectCountry font12pt titleColor font-weight-bold",
                        attrs: {
                          items: _vm.paises,
                          "full-width": "",
                          "item-text": "name",
                          "item-value": "id",
                          solo: "",
                          "hide-details": "",
                          flat: "",
                          "single-line": "",
                          "return-object": "",
                          "background-color": "#f1f3f8",
                          "item-color": "#00a5ff",
                        },
                        model: {
                          value: _vm.countrySelected,
                          callback: function ($$v) {
                            _vm.countrySelected = $$v
                          },
                          expression: "countrySelected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mb-3", attrs: { sm: 6, md: 6, lg: 4 } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "invisible_" }, [_vm._v(".")]),
                  ]),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end align-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mainBtn mx-2",
                            attrs: {
                              depressed: "",
                              color: "bgColorPrincipal",
                              disabled: _vm.buttonDisabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addNewDriver()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "white--text" }, [
                              _vm._v("Create"),
                            ]),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mainBtn",
                            attrs: { depressed: "", color: "bgColorPrincipal" },
                            on: {
                              click: function ($event) {
                                _vm.openModal = true
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "white--text mr-1",
                                staticStyle: { "font-size": "20px" },
                              },
                              [_vm._v("+")]
                            ),
                            _c("span", { staticClass: "white--text" }, [
                              _vm._v(" ISC Drivers"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "context-box__table" },
        [
          _c("v-col", [
            _c("div", { staticClass: "context-box__table__header d-flex" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("span", { staticClass: "font12pt leyendColor" }, [
                  _vm._v("Driver list"),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "bgColorBase context-box__table__body" },
              [
                _c("v-virtual-scroll", {
                  attrs: {
                    bench: 10,
                    items: _vm.allDrivers,
                    "max-height": "168",
                    "item-height": "42",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-row",
                            {
                              style: {
                                backgroundColor: item.geo_dashboard
                                  ? "#fbfbfb"
                                  : "inherit",
                              },
                            },
                            [
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      overflow: "hidden",
                                      "white-space": "nowrap",
                                      "text-overflow": "ellipsis",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font12pt titleColor ml-10",
                                      },
                                      [_vm._v(" " + _vm._s(item.term) + " ")]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "font12pt leyendColor" },
                                    [_vm._v(" " + _vm._s(item.country) + " ")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", align: "end" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "11",
                                            color: "#a2acc4",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteDriver(item)
                                            },
                                          },
                                        },
                                        [_vm._v("fas fa-trash")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", align: "end" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      item.active === true
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "12",
                                                color: "#00a5ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  item.active = false
                                                },
                                              },
                                            },
                                            [_vm._v("fas fa-eye")]
                                          )
                                        : _vm._e(),
                                      item.active === false
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "12",
                                                color: "#a2acc4",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  item.active = true
                                                },
                                              },
                                            },
                                            [_vm._v("fas fa-eye")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.openModal
        ? _c("AddIscDriverModal", {
            attrs: { driverType: "tw" },
            on: { closeIscDriverModal: _vm.closeIscDriverModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }