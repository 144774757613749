var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.overviews
        ? _c("v-main", { staticClass: "center-content overview_content" }, [
            _vm.contextAccountId != "geo-dashboard"
              ? _c(
                  "div",
                  [
                    _c("Barcontext", {
                      attrs: {
                        contextName: _vm.contextName,
                        "context-keywords": _vm.arrayKeywords,
                      },
                      on: {
                        close: _vm.refreshData,
                        closeMultiSelect: _vm.refreshData,
                        dataLoading: _vm.setLoader,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.contextAccountId == "geo-dashboard"
              ? _c(
                  "div",
                  [
                    _c("BarContextBoards", {
                      attrs: {
                        contextName: _vm.contextName,
                        "context-keywords": _vm.arrayKeywords,
                      },
                      on: {
                        updateFbDrivers: _vm.updateFbDrivers,
                        updateTwDrivers: _vm.updateTwDrivers,
                        updateIgDrivers: _vm.updateIgDrivers,
                        updateLkDrivers: _vm.updateLkDrivers,
                        updateNwsDrivers: _vm.updateNwsDrivers,
                        close: function ($event) {
                          return _vm.refreshData()
                        },
                        closeMultiSelect: function ($event) {
                          return _vm.refreshData()
                        },
                        dataLoading: _vm.setLoader,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "overview-content__resume-cards mb-5" },
              [
                _vm.overviews
                  ? _c("ResumeCards", {
                      attrs: {
                        fb_active_drivers: _vm.filterData.fb_active_drivers,
                        tw_active_drivers: _vm.filterData.tw_active_drivers,
                        ig_active_drivers: _vm.filterData.ig_active_drivers,
                        lk_active_drivers: _vm.filterData.lk_active_drivers,
                        nws_active_drivers: _vm.filterData.nws_active_drivers,
                        filterData: _vm.filterData,
                        overviews: _vm.overviews,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "component-content__tabs mb-5 pb-5",
                staticStyle: { position: "relative" },
              },
              [
                _vm.loadingTab
                  ? _c(
                      "div",
                      [
                        _c("v-overlay", {
                          attrs: {
                            absolute: true,
                            opacity: 0.6,
                            color: "#f3f4f7",
                            "z-index": "4",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              left: "48%",
                              top: "150px",
                              "z-index": "3",
                            },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                size: "64",
                                color: "#657185",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-tabs",
                  {
                    staticClass: "component-content__tabs-menu",
                    attrs: {
                      centered: "",
                      "slider-color": "#00A5FF",
                      "background-color": "#f3f4f7",
                      height: "51",
                      grow: "",
                    },
                    on: { change: _vm.changeTab },
                    model: {
                      value: _vm.selectedTab,
                      callback: function ($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab",
                    },
                  },
                  [
                    _c("v-tab", { key: "0" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font15pt titleColor font-weight-bold text-capitalize",
                        },
                        [_vm._v("Samples")]
                      ),
                    ]),
                    _c("v-tab", { key: "1" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font15pt titleColor font-weight-bold text-capitalize",
                        },
                        [_vm._v("Sentiment")]
                      ),
                    ]),
                    _c("v-tab", { key: "2" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font15pt titleColor font-weight-bold text-capitalize",
                        },
                        [_vm._v("Demographics")]
                      ),
                    ]),
                    _c("v-tab", { key: "3" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font15pt titleColor font-weight-bold text-capitalize",
                        },
                        [_vm._v("Influencer")]
                      ),
                    ]),
                    _c("v-tab", { key: "4" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font15pt titleColor font-weight-bold text-capitalize",
                        },
                        [_vm._v("Trend Topic")]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    staticClass: "overview-content__tabs__info",
                    attrs: { touchless: "" },
                    model: {
                      value: _vm.selectedTab,
                      callback: function ($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab",
                    },
                  },
                  [
                    _c(
                      "v-tab-item",
                      {
                        attrs: {
                          transition: "none",
                          "reverse-transition": "fade-transition",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-padding center-main extra-margin",
                          },
                          [
                            _vm.ghostImages.samples.active
                              ? _c("img", {
                                  staticClass: "ghostImage",
                                  staticStyle: { width: "100%" },
                                  attrs: { src: _vm.ghostImages.samples.url },
                                })
                              : _vm._e(),
                            !_vm.ghostImages.samples.active
                              ? _c(
                                  "div",
                                  [
                                    !_vm.loadingTab
                                      ? _c("SamplesContent", {
                                          attrs: {
                                            fb_active_drivers:
                                              _vm.filterData.fb_active_drivers,
                                            tw_active_drivers:
                                              _vm.filterData.tw_active_drivers,
                                            ig_active_drivers:
                                              _vm.filterData.ig_active_drivers,
                                            lk_active_drivers:
                                              _vm.filterData.lk_active_drivers,
                                            nws_active_drivers:
                                              _vm.filterData.nws_active_drivers,
                                            overviewSamples:
                                              _vm.overviewSamples,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-tab-item",
                      {
                        attrs: {
                          transition: "none",
                          "reverse-transition": "fade-transition",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-padding center-main extra-margin",
                          },
                          [
                            _vm.ghostImages.sentiment.active
                              ? _c("img", {
                                  staticClass: "ghostImage",
                                  staticStyle: { width: "100%" },
                                  attrs: { src: _vm.ghostImages.sentiment.url },
                                })
                              : _vm._e(),
                            !_vm.ghostImages.sentiment.active
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("SentimentShared", {
                                              attrs: {
                                                sentiments:
                                                  _vm.overviewSentiment,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("SentimentByDay", {
                                              attrs: {
                                                sentiments:
                                                  _vm.overviewSentiment,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "min-height-200" },
                                          [
                                            _c("SentimentByChannel", {
                                              attrs: {
                                                networks:
                                                  _vm.overviewSentiment
                                                    .socialNetworksData
                                                    .sourceSnetworkId,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("SentimentWords", {
                                              attrs: {
                                                wordsData:
                                                  _vm.overviewSentimentWords
                                                    .words,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-tab-item",
                      {
                        attrs: {
                          transition: "none",
                          "reverse-transition": "fade-transition",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-padding center-main extra-margin",
                          },
                          [
                            _vm.ghostImages.demographics.active
                              ? _c("img", {
                                  staticClass: "ghostImage",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    src: _vm.ghostImages.demographics.url,
                                  },
                                })
                              : _vm._e(),
                            !_vm.ghostImages.demographics.active
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("GenderSentiment", {
                                              attrs: {
                                                genders:
                                                  _vm.overviewDemographics
                                                    .gender,
                                                emotions:
                                                  _vm.overviewDemographics
                                                    .emotions,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("GenderDailyProgression", {
                                              attrs: {
                                                histograms:
                                                  _vm.overviewDemographics
                                                    .histograms,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("MaleFemaleSentiment", {
                                              attrs: {
                                                histograms:
                                                  _vm.overviewDemographics
                                                    .histograms,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("EmotionsDailyProgression", {
                                              attrs: {
                                                histograms:
                                                  _vm.overviewDemographics
                                                    .histograms,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-tab-item",
                      {
                        attrs: {
                          transition: "none",
                          "reverse-transition": "fade-transition",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-padding center-main extra-margin",
                          },
                          [
                            _vm.ghostImages.influencer.active
                              ? _c("img", {
                                  staticClass: "ghostImage",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    src: _vm.ghostImages.influencer.url,
                                  },
                                })
                              : _vm._e(),
                            !_vm.ghostImages.influencer.active
                              ? _c(
                                  "div",
                                  [
                                    _c("InfluencersContent", {
                                      attrs: {
                                        influencersAccount:
                                          _vm.overviewInfluencers.influencers,
                                        fbpost: _vm.overviewInfluencers.fbpost,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-tab-item",
                      {
                        attrs: {
                          transition: "none",
                          "reverse-transition": "fade-transition",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-padding center-main extra-margin",
                          },
                          [
                            _vm.ghostImages.trendtopic.active
                              ? _c("img", {
                                  staticClass: "ghostImage",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    src: _vm.ghostImages.trendtopic.url,
                                  },
                                })
                              : _vm._e(),
                            !_vm.ghostImages.trendtopic.active
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "container-row" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "colTopPopularHashtags",
                                                staticStyle: {
                                                  "padding-right": "50px",
                                                },
                                                attrs: { md: "7" },
                                              },
                                              [
                                                _c("TopPopularHashtags", {
                                                  attrs: {
                                                    hashtags:
                                                      _vm.overviewTrendTopics
                                                        .hashtags,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "colTopTopicsCluster",
                                                attrs: { md: "5" },
                                              },
                                              [
                                                _c("TopTopicsCluster", {
                                                  attrs: {
                                                    influencerAccounts:
                                                      _vm.overviewTrendTopics
                                                        .influ_accounts,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c("AllPopularHashtags", {
                                                  attrs: {
                                                    histograms:
                                                      _vm.overviewTrendTopics
                                                        .histograms,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "50px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "SentimentByAllPopularTerms",
                                                  {
                                                    attrs: {
                                                      histograms:
                                                        _vm.overviewTrendTopics
                                                          .histograms,
                                                      histogramsbucket:
                                                        _vm.histogramsbucket,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "TopPublicationsRelatedTrendingTopic",
                                                  {
                                                    attrs: {
                                                      samples:
                                                        _vm.overviewTrendTopics
                                                          .samples,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }