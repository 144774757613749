var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 mt-5 pt-5 mb-5 pb-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mb-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Sentiment By Day"),
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  attrs: { right: "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v("Social Networks")]),
                  _c("v-tab", [_vm._v("Newspapers")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("apexchart", {
                attrs: {
                  type: "bar",
                  height: "400",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }