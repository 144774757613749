var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray d-flex align-center flex-column bgColorBase py-3 rounded-lg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font12pt parColor font-weight-light mr-1",
                              },
                              [_vm._v("Samples")]
                            ),
                            _c("tooltipComponent", {
                              attrs: {
                                tooltipText:
                                  "Total sample according to the selected date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "h1",
                          {
                            staticClass:
                              "font31pt titleColor font-weight-black text-center",
                          },
                          [_vm._v(" " + _vm._s(_vm.mentions) + " ")]
                        ),
                      ]
                    ),
                  ]),
                  _c("v-col", { attrs: { md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray d-flex align-center flex-column bgColorBase py-3 rounded-lg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font12pt parColor font-weight-light mr-1",
                              },
                              [_vm._v("Avg / Day")]
                            ),
                            _c("tooltipComponent", {
                              attrs: {
                                tooltipText:
                                  "Average of the samples according to the selected date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "h1",
                          {
                            staticClass:
                              "font31pt titleColor font-weight-black text-center",
                          },
                          [_vm._v(" " + _vm._s(_vm.average) + " ")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "mt-5 align-center" },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c(
                        "h1",
                        { staticClass: "font15pt font-weight-bold mr-2" },
                        [_vm._v("Samples by day")]
                      ),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    _vm._l(_vm.networks, function (network, index) {
                      return _c("v-checkbox", {
                        key: index,
                        staticClass: "checkbox-filter ml-3",
                        attrs: {
                          label: network.name,
                          ripple: false,
                          value: network.name,
                          "hide-details": "",
                          dense: "",
                        },
                        on: { change: _vm.updateData },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "graphsamples" },
            [
              _c("apexchart", {
                ref: "chart",
                attrs: {
                  type: "area",
                  height: "350",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }