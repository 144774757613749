var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c("v-row", { staticClass: "d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center mt-5" },
          [
            _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
              _vm._v("Top 10 Posts related Trending Topic"),
            ]),
            _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip1 } }),
          ],
          1
        ),
        _c("div", { staticClass: "mb-5" }, [
          _c(
            "span",
            { staticClass: "d-block font10pt leyendColor" },
            [
              _vm._v("On Facebook, Twitter, Instagram & Linkedin "),
              _c("tooltipComponent", {
                attrs: { tooltipText: _vm.tooltip2, tooltipSize: 10 },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "table--general" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.samplesList,
              "hide-default-header": "",
              "hide-default-footer": true,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              light: true,
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function ({ props }) {
                    return [
                      _c("thead", { staticClass: "v-data-table-header" }, [
                        _c(
                          "tr",
                          _vm._l(props.headers, function (header) {
                            return _c(
                              "th",
                              {
                                key: header.value,
                                staticClass:
                                  "font-weight-bold titleColor font13pt",
                                staticStyle: { cursor: "pointer" },
                                attrs: { width: header.width },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSort(header)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(header.text) + " "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "v-data-table-header__icon",
                                    staticStyle: { "font-size": "18px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.sortDesc
                                            ? "mdi-sort-alphabetical-descending"
                                            : "mdi-sort-alphabetical-ascending"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.postOrTweet`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "span",
                        { staticClass: "font12 d-block span-table pt-2 pb-2" },
                        [_vm._v(" " + _vm._s(item.postOrTweet) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: `item.user`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font12 d-block span-table text-truncate",
                          staticStyle: { "max-width": "150px" },
                        },
                        [_vm._v(" " + _vm._s(item.user) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: `item.socialNetwork`,
                  fn: function ({ item }) {
                    return [
                      item.socialNetwork.includes("fb")
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { small: "", color: "#598ec2" },
                            },
                            [_vm._v(" fab fa-facebook ")]
                          )
                        : _vm._e(),
                      item.socialNetwork.includes("tw")
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { small: "", color: "#1da1f2" },
                            },
                            [_vm._v(" fab fa-twitter ")]
                          )
                        : _vm._e(),
                      item.socialNetwork.includes("ig")
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { small: "", color: "#ff69a1" },
                            },
                            [_vm._v(" fab fa-instagram ")]
                          )
                        : _vm._e(),
                      item.socialNetwork.includes("lk")
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { small: "", color: "#0a66c2" },
                            },
                            [_vm._v(" fab fa-linkedin ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }