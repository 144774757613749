var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("SamplesCard", {
        attrs: { histogramsSourceQuery: _vm.histogramsSourceQuery },
      }),
      _c("SentimentCard", { attrs: { histograms: _vm.histograms } }),
      _c("DemographicsCard", { attrs: { gender: _vm.gender } }),
      _c("InfluencerCard", {
        attrs: { influencerAccounts: _vm.influ_accounts },
      }),
      _c("TrendTopicCard", { attrs: { topics: _vm.words } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }