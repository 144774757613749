var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        { attrs: { "align-items": "center" } },
        [
          _c("v-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                  _vm._v(" Gender Daily Progression "),
                ]),
                _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
              ],
              1
            ),
            _c("div", { staticClass: "mb-5" }, [
              _c("span", { staticClass: "d-block font10pt leyendColor" }, [
                _vm._v("At all Social Networks"),
              ]),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end align-center",
              attrs: { md: "6" },
            },
            [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sentimentSelected,
                      expression: "sentimentSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: { type: "radio", id: "check-all", value: "all" },
                  domProps: { checked: _vm._q(_vm.sentimentSelected, "all") },
                  on: {
                    change: function ($event) {
                      _vm.sentimentSelected = "all"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-all" },
                  },
                  [_vm._v("All")]
                ),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sentimentSelected,
                      expression: "sentimentSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: { type: "radio", id: "check-positive", value: "pos" },
                  domProps: { checked: _vm._q(_vm.sentimentSelected, "pos") },
                  on: {
                    change: function ($event) {
                      _vm.sentimentSelected = "pos"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-positive" },
                  },
                  [_vm._v("Positive")]
                ),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sentimentSelected,
                      expression: "sentimentSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: { type: "radio", id: "check-negative", value: "neg" },
                  domProps: { checked: _vm._q(_vm.sentimentSelected, "neg") },
                  on: {
                    change: function ($event) {
                      _vm.sentimentSelected = "neg"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-negative" },
                  },
                  [_vm._v("Negative")]
                ),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sentimentSelected,
                      expression: "sentimentSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: { type: "radio", id: "check-neutral", value: "neu" },
                  domProps: { checked: _vm._q(_vm.sentimentSelected, "neu") },
                  on: {
                    change: function ($event) {
                      _vm.sentimentSelected = "neu"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-neutral" },
                  },
                  [_vm._v("Neutral")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "graphgenderdaily" },
            [
              _c("apexchart", {
                attrs: {
                  height: "350",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }