var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        { attrs: { "align-items": "center" } },
        [
          _c("v-col", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                  _vm._v(" Emotions Daily Progression "),
                ]),
                _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
              ],
              1
            ),
            _c("div", { staticClass: "mb-5" }, [
              _c("span", { staticClass: "d-block font10pt leyendColor" }, [
                _vm._v("At all Social Networks"),
              ]),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end align-center",
              attrs: { md: "6" },
            },
            [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.genderSelected,
                      expression: "genderSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: {
                    type: "radio",
                    id: "check-gender-all",
                    value: "all",
                  },
                  domProps: { checked: _vm._q(_vm.genderSelected, "all") },
                  on: {
                    change: function ($event) {
                      _vm.genderSelected = "all"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-gender-all" },
                  },
                  [_vm._v("All")]
                ),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.genderSelected,
                      expression: "genderSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: {
                    type: "radio",
                    id: "check-gender-male",
                    value: "male",
                  },
                  domProps: { checked: _vm._q(_vm.genderSelected, "male") },
                  on: {
                    change: function ($event) {
                      _vm.genderSelected = "male"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-gender-male" },
                  },
                  [_vm._v("Male")]
                ),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.genderSelected,
                      expression: "genderSelected",
                    },
                  ],
                  staticClass: "checkbox",
                  attrs: {
                    type: "radio",
                    id: "check-gender-female",
                    value: "female",
                  },
                  domProps: { checked: _vm._q(_vm.genderSelected, "female") },
                  on: {
                    change: function ($event) {
                      _vm.genderSelected = "female"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticClass: "font12pt parColor",
                    attrs: { for: "check-gender-female" },
                  },
                  [_vm._v("Female")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("apexchart", {
                attrs: {
                  height: "350",
                  options: _vm.chartOptions,
                  series: _vm.series,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }