var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "loaderTransveral d-flex align-center justify-center" },
      [
        _c("div", {}, [
          _c("img", {
            staticClass: "img-loader",
            attrs: {
              src: "https://listen-images.s3.us-east-2.amazonaws.com/isotipo_isc_140x164.svg",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }