var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("v-row", { staticClass: "d-flex flex-column mb-5" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center mt-5" },
          [
            _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
              _vm._v("All Popular Hashtags"),
            ]),
            _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } }),
          ],
          1
        ),
        _c("div", { staticClass: "mb-5" }, [
          _c("span", { staticClass: "d-block font10pt leyendColor" }, [
            _vm._v("On Facebook, Twitter, Instagram & Linkedin"),
          ]),
        ]),
      ]),
      _c("timeline", {
        ref: "timeline",
        attrs: { items: _vm.items, options: _vm.options },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }