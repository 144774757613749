var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "v-main__influencers" },
    [
      _c(
        "v-row",
        { staticClass: "mb-5 pb-5" },
        [
          _c("TopInfluencerAccounts", {
            attrs: { dataInfluncers: _vm.influencers },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-5" },
        [
          _c("InfluencersRanking", {
            attrs: { dataInfluncers: _vm.influencers },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }