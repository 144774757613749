var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.overviewSample
    ? _c(
        "div",
        [
          _c("SamplesGraphic", {
            attrs: {
              histograms: _vm.histograms,
              mentions: _vm.mentions,
              averageSamples: _vm.average_samples,
            },
          }),
          _c("SamplesDrivers", { attrs: { drivers: _vm.source_query } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }