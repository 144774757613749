var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "mt-5", attrs: { md: "12" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "h1",
                      { staticClass: "font15pt font-weight-bold mr-2" },
                      [_vm._v("Drivers")]
                    ),
                    _c("tooltipComponent", {
                      attrs: { tooltipText: _vm.tooltip },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { right: "" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tab", [_vm._v("Social Networks")]),
                      _c("v-tab", [_vm._v("Newspapers")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "12 table--general" } },
        [
          _vm._l([_vm.socialNetworks, _vm.newspapers], function (network, key) {
            return [
              _vm.tab === key
                ? _c("v-data-table", {
                    attrs: {
                      headers: network.headers,
                      items: network.drivers,
                      "hide-default-header": false,
                      "hide-default-footer": true,
                      light: true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.type`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { small: "", color: item.color },
                                },
                                [_vm._v(" " + _vm._s(item.icon) + " ")]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.sentiment`,
                          fn: function ({ item }) {
                            return [
                              item.sentiment == "neu"
                                ? _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "#828a96" } },
                                    [_vm._v(" far fa-hand-point-right ")]
                                  )
                                : _vm._e(),
                              item.sentiment == "pos"
                                ? _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "#9fd895" } },
                                    [_vm._v(" far fa-thumbs-up ")]
                                  )
                                : _vm._e(),
                              item.sentiment == "neg"
                                ? _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "#ff7e7e" } },
                                    [_vm._v(" far fa-thumbs-down ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }