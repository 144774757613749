var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-main", [
    _c(
      "div",
      {
        staticClass: "modalcontent elevation-2 pa-3",
        staticStyle: { "background-color": "white" },
      },
      [
        _c(
          "div",
          { staticClass: "modalcontent__title pb-4 position-relative" },
          [
            _c(
              "h2",
              {
                staticClass: "font15pt titleColor font-weight-bold",
                staticStyle: {
                  "text-overflow": "ellipsis",
                  "white-space": "nowrap",
                  "max-width": "25rem",
                  overflow: "hidden",
                },
              },
              [_vm._v(_vm._s(_vm.contentModal.word))]
            ),
            _c("span", { staticClass: "leyendColor font10pt" }, [
              _vm._v("Top 3 Tweets / Post / News"),
            ]),
            _c(
              "div",
              {
                staticClass: "close-modal-btn position-absolute",
                on: {
                  click: function ($event) {
                    return _vm.closeModalWord()
                  },
                },
              },
              [
                _c("v-icon", { attrs: { color: "#37afef" } }, [
                  _vm._v(" fas fa-times-circle "),
                ]),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "modalcontent__content" }, [
          _c(
            "div",
            { staticClass: "modalcontent__content__item pr-3" },
            _vm._l(_vm.contentModal.modalcontent, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "mb-5 pb-3 border_bt" },
                [
                  _c(
                    "div",
                    { staticClass: "modalcontent__content__user d-flex" },
                    [
                      _c("v-avatar", { attrs: { size: "40" } }, [
                        _c("img", {
                          attrs: { alt: "user", src: item.userAvatar },
                          on: {
                            error: function ($event) {
                              $event.target.src =
                                "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modalcontent__content__userinfo d-flex flex-column justify-end pl-2",
                        },
                        [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "font12pt font-weight-bold" },
                              [_vm._v(" " + _vm._s(item.userName) + " ")]
                            ),
                            _c("span"),
                            item.socialNetwork != "nws"
                              ? _c(
                                  "span",
                                  { staticClass: "ColorMale font10pt" },
                                  [
                                    item.socialNetwork == "tw"
                                      ? _c("i", [_vm._v("@")])
                                      : _vm._e(),
                                    _vm._v(_vm._s(item.userName) + " "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "leyendColor font10pt" }, [
                            _c("span", {}, [
                              _vm._v(" " + _vm._s(item.postDate) + " "),
                            ]),
                            item.socialNetwork != "nws"
                              ? _c("span", {}, [
                                  _vm._v(
                                    " - " + _vm._s(item.postTime) + " hours "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modalcontent__content__icon ml-auto d-flex justify-center",
                        },
                        [
                          item.socialNetwork == "fb"
                            ? _c(
                                "v-icon",
                                { attrs: { small: "", color: "#1877f2" } },
                                [_vm._v(" fab fa-facebook ")]
                              )
                            : _vm._e(),
                          item.socialNetwork == "tw"
                            ? _c(
                                "v-icon",
                                { attrs: { small: "", color: "#1da1f2" } },
                                [_vm._v(" fab fa-twitter ")]
                              )
                            : _vm._e(),
                          item.socialNetwork == "ig"
                            ? _c(
                                "v-icon",
                                { attrs: { small: "", color: "#ff69a1" } },
                                [_vm._v(" fab fa-instagram ")]
                              )
                            : _vm._e(),
                          item.socialNetwork == "lk"
                            ? _c(
                                "v-icon",
                                { attrs: { small: "", color: "#0a66c2" } },
                                [_vm._v(" fab fa-linkedin ")]
                              )
                            : _vm._e(),
                          item.socialNetwork == "nws"
                            ? _c(
                                "v-icon",
                                { attrs: { small: "", color: "#444444" } },
                                [_vm._v(" fa-solid fa-newspaper ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modalcontent__content__comments mt-2" },
                    [
                      item.is_rtw
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "modalcontent__content__comments__rtw-user",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "pl-1 font10pt leyendColor" },
                                [_vm._v("retweeted of ")]
                              ),
                              _c(
                                "span",
                                { staticClass: "font10pt ColorMale" },
                                [_vm._v("@" + _vm._s(item.retweetProfile))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modalcontent__content__comments__text pt-2",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "parColor font12pt modal_text_item",
                            },
                            [_vm._v(" " + _vm._s(item.postcontent) + " ")]
                          ),
                        ]
                      ),
                      item.postImage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "modalcontent__content__comments__image mt-5",
                            },
                            [_c("v-img", { attrs: { src: item.postImage } })],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modalcontent__content__comments__reactions d-flex mt-5 mb-2",
                        },
                        [
                          item.socialNetwork === "fb" ||
                          item.socialNetwork === "lk"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__content__comments__reactions__item",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "10", color: "#a2acc4" } },
                                    [_vm._v(" fas fa-thumbs-up ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "leyendColor font10pt pl-1",
                                    },
                                    [_vm._v(_vm._s(item.likeCount))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.socialNetwork == "fb" ||
                          item.socialNetwork == "lk" ||
                          item.socialNetwork == "ig"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__content__comments__reactions__item pl-2",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "10", color: "#a2acc4" } },
                                    [_vm._v(" fas fa-share ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "leyendColor font10pt pl-1",
                                    },
                                    [_vm._v(_vm._s(item.sharedCount))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.socialNetwork == "fb" ||
                          item.socialNetwork == "lk" ||
                          item.socialNetwork == "ig"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__content__comments__reactions__item pl-2",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "10", color: "#a2acc4" } },
                                    [_vm._v(" fas fa-comments ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "leyendColor font10pt pl-1",
                                    },
                                    [_vm._v(_vm._s(item.comentsCount))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.socialNetwork == "tw"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__content__comments__reactions__item pl-2",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "10", color: "#a2acc4" } },
                                    [_vm._v(" fas fa-retweet ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "leyendColor font10pt pl-1",
                                    },
                                    [_vm._v(_vm._s(item.retweetCount))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.socialNetwork == "tw" ||
                          item.socialNetwork == "ig"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "modalcontent__content__comments__reactions__item pl-2",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "10", color: "#a2acc4" } },
                                    [_vm._v(" fas fa-heart ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "leyendColor font10pt pl-1",
                                    },
                                    [_vm._v(_vm._s(item.likeCount))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }